import React, { useState } from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { theme } from "./theme";

const LoginContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${theme.colors.background};
  font-family: "Inter", sans-serif;
`;

const LoginCard = styled(motion.div)`
  background-color: ${theme.colors.surface};
  padding: ${theme.spacing.xlarge};
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  color: ${theme.colors.primary};
  text-align: center;
  margin-bottom: ${theme.spacing.large};
`;

const Input = styled(motion.input)`
  width: 100%;
  padding: ${theme.spacing.medium};
  margin-bottom: ${theme.spacing.medium};
  border: 1px solid ${theme.colors.border};
  border-radius: 0.5rem;
  font-size: ${theme.fontSizes.medium};
`;

const Button = styled(motion.button)`
  width: 100%;
  padding: ${theme.spacing.medium};
  background-color: ${theme.colors.primary};
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: ${theme.fontSizes.medium};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${theme.colors.secondary};
  }
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
  exit: { opacity: 0, transition: { duration: 0.5 } },
};

const cardVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 300, damping: 30 },
  },
};

const inputVariants = {
  focus: { scale: 1.02 },
};

const buttonVariants = {
  hover: { scale: 1.05 },
  tap: { scale: 0.95 },
};

export function LoginPage({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin();
  };

  return (
    <LoginContainer
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <LoginCard variants={cardVariants}>
        <Title>Greenhouse Login</Title>
        <form onSubmit={handleSubmit}>
          <Input
            variants={inputVariants}
            whileFocus="focus"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            variants={inputVariants}
            whileFocus="focus"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variants={buttonVariants}
            whileHover="hover"
            whileTap="tap"
            type="submit"
          >
            Login
          </Button>
        </form>
      </LoginCard>
    </LoginContainer>
  );
}
