export const theme = {
    colors: {
      primary: "#4CAF50",
      secondary: "#45a049",
      background: "#F2F2F7",
      surface: "#FFFFFF",
      text: "#000000",
      textSecondary: "#8E8E93",
      border: "#E5E5EA",
      success: "#34C759",
      danger: "#FF3B30",
    },
    fontSizes: {
      small: "0.875rem",
      medium: "1rem",
      large: "1.25rem",
      xlarge: "1.5rem",
      xxlarge: "2rem",
    },
    spacing: {
      small: "0.5rem",
      medium: "1rem",
      large: "1.5rem",
      xlarge: "2rem",
    },
  };
  