import React from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import { theme } from "./theme";

const Container = styled(motion.div)`
  padding: ${theme.spacing.large};
  background-color: ${theme.colors.background};
  min-height: 100vh;
  font-family: "Inter", sans-serif;
`;

const Title = styled.h1`
  color: ${theme.colors.primary};
  margin-bottom: ${theme.spacing.large};
`;

const GreenhouseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: ${theme.spacing.large};
`;

const GreenhouseCard = styled(motion.div)`
  background-color: ${theme.colors.surface};
  padding: ${theme.spacing.large};
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const GreenhouseName = styled.h2`
  color: ${theme.colors.text};
  margin-bottom: ${theme.spacing.small};
`;

const GreenhouseLocation = styled.p`
  color: ${theme.colors.textSecondary};
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
  exit: { opacity: 0, transition: { duration: 0.5 } },
};

const cardVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: { y: 0, opacity: 1 },
  hover: {
    scale: 1.05,
    transition: { type: "spring", stiffness: 300, damping: 30 },
  },
  tap: { scale: 0.95 },
};

export function GreenhouseListPage({ onSelectGreenhouse }) {
  const greenhouses = [
    { id: 1, name: "Greenhouse Alpha", location: "North Field" },
    { id: 2, name: "Greenhouse Beta", location: "South Field" },
    { id: 3, name: "Greenhouse Gamma", location: "East Field" },
  ];

  const handlers = useSwipeable({
    onSwipedRight: () => {
      // Handle swipe right (e.g., go back)
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  });

  return (
    <Container
      {...handlers}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Title>My Greenhouses</Title>
      <GreenhouseGrid>
        {greenhouses.map((greenhouse) => (
          <GreenhouseCard
            key={greenhouse.id}
            variants={cardVariants}
            whileHover="hover"
            whileTap="tap"
            onClick={() => onSelectGreenhouse(greenhouse)}
          >
            <GreenhouseName>{greenhouse.name}</GreenhouseName>
            <GreenhouseLocation>{greenhouse.location}</GreenhouseLocation>
          </GreenhouseCard>
        ))}
      </GreenhouseGrid>
    </Container>
  );
}
