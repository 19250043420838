import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { motion, AnimatePresence } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Thermometer,
  Droplets,
  Wind,
  Clock,
  Trash2,
  Power,
} from "lucide-react";
import { theme } from "./theme";

const Container = styled(motion.div)`
  min-height: 100vh;
  padding: ${theme.spacing.large};
  background-color: ${theme.colors.background};
  font-family: "Inter", sans-serif;
`;


const Card = styled(motion.div)`
  background-color: ${theme.colors.surface};
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: ${theme.spacing.large};
`;

const StyledSlider = styled.input`
  width: 100%;
  accent-color: ${theme.colors.primary};
`;

const StyledInput = styled.input`
  border: 1px solid ${theme.colors.border};
  border-radius: 0.25rem;
  padding: ${theme.spacing.small};
  width: 100%;
  color: ${theme.colors.text};
  font-size: ${theme.fontSizes.medium};
`;

const StyledButton = styled(motion.button)`
  background-color: ${(props) =>
    props.variant === "danger" ? theme.colors.danger : theme.colors.primary};
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding: ${theme.spacing.small} ${theme.spacing.medium};
  font-size: ${theme.fontSizes.medium};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) =>
      props.variant === "danger" ? "#cc2a24" : theme.colors.secondary};
  }
`;

const SwitchContainer = styled.div`
  width: 51px;
  height: 31px;
  background-color: ${(props) =>
    props.checked ? theme.colors.success : "#e9e9ea"};
  border-radius: 31px;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

const SwitchHandle = styled(motion.div)`
  width: 27px;
  height: 27px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const Switch = ({ checked, onCheckedChange }) => (
  <SwitchContainer checked={checked} onClick={() => onCheckedChange(!checked)}>
    <SwitchHandle
      layout
      transition={{
        type: "spring",
        stiffness: 700,
        damping: 30,
      }}
      style={{ x: checked ? 22 : 0 }}
    />
  </SwitchContainer>
);

const TouchFriendlyButton = styled(motion.button)`
  background-color: ${(props) =>
    props.active ? theme.colors.success : theme.colors.danger};
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.fontSizes.large};
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${theme.spacing.large};

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const containerVariants = {
  hidden: { opacity: 0, x: "100%" },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring", stiffness: 300, damping: 30 },
  },
  exit: { opacity: 0, x: "-100%", transition: { duration: 0.3 } },
};

const cardVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 300, damping: 30 },
  },
};


export const useSensorData = () => {
  const [sensorData, setSensorData] = useState({
    ec: 0,
    humd: 0,
    temp: 0,
  });

  useEffect(() => {
    const fetchSensorData = async () => {
      try {
        const response = await fetch('https://sgh_0002.tensaiinnovations.com/api/get/latest_data');
        if (!response.ok) {
          throw new Error('Failed to fetch latest data');
        }
        const data = await response.json();
        console.log('API response data:', data); // Log the entire JSON response object here

        setSensorData({
          ec: data.ec,
          humd: data.humd,
          temp: data.temp,
        });
      } catch (error) {
        console.error('Error fetching sensor data:', error);
      }
    };

    fetchSensorData();
  }, []);

  return sensorData;
};

const SensorCard = ({
  icon: Icon,
  title,
  value,
  unit,
  data,
  isExpanded,
  onClick,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showChartByDefault = windowWidth >= 768;

  return (
    <Card
      onClick={!showChartByDefault ? onClick : undefined}
      style={{ cursor: !showChartByDefault ? "pointer" : "default" }}
    >
      <motion.div
        whileHover={!showChartByDefault ? { scale: 1.05 } : {}}
        whileTap={!showChartByDefault ? { scale: 0.95 } : {}}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Icon size={24} color={theme.colors.primary} />
          <div style={{ textAlign: "right" }}>
            <p
              style={{
                fontSize: theme.fontSizes.small,
                color: theme.colors.textSecondary,
                margin: 0,
              }}
            >
              {title}
            </p>
            <p
              style={{
                fontSize: theme.fontSizes.xlarge,
                fontWeight: "bold",
                color: theme.colors.text,
                margin: 0,
              }}
            >
              {value}
              <span
                style={{
                  fontSize: theme.fontSizes.small,
                  fontWeight: "normal",
                }}
              >
                {unit}
              </span>
            </p>
          </div>
        </div>
        {(showChartByDefault || isExpanded) && (
          <div style={{ marginTop: theme.spacing.medium, height: 200 }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data}>
                <XAxis dataKey="name" stroke={theme.colors.textSecondary} />
                <YAxis stroke={theme.colors.textSecondary} />
                <Tooltip
                  contentStyle={{
                    backgroundColor: theme.colors.surface,
                    borderColor: theme.colors.border,
                  }}
                  labelStyle={{ color: theme.colors.text }}
                />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke={theme.colors.primary}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </motion.div>
    </Card>
  );
};


const ECAbMixControl = ({ ecSet, onEcSetChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleValueClick = () => {
    setIsEditing(true);
  };

  const handleValueChange = (e) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 5000) {
      onEcSetChange(newValue);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsEditing(false);
    }
  };

  return (
    <Card>
      <h2
        style={{
          fontSize: theme.fontSizes.xlarge,
          fontWeight: "bold",
          marginBottom: theme.spacing.medium,
          color: theme.colors.text,
        }}
      >
        EC AB Mix Control
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing.large,
        }}
      >
        <div>
          <label
            htmlFor="ec-ab-mix-slider"
            style={{
              display: "block",
              fontSize: theme.fontSizes.small,
              fontWeight: "500",
              marginBottom: theme.spacing.small,
              color: theme.colors.textSecondary,
            }}
          >
            EC AB Mix Setpoint
          </label>
          <StyledSlider
            type="range"
            id="ec-ab-mix-slider"
            min={0}
            max={5000}
            value={ecSet}
            onChange={(e) => onEcSetChange(parseInt(e.target.value))}
          />
        </div>
        {isEditing ? (
          <StyledInput
            ref={inputRef}
            type="number"
            value={ecSet}
            onChange={handleValueChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            min={0}
            max={5000}
            style={{ fontSize: theme.fontSizes.xxlarge, textAlign: "center" }}
          />
        ) : (
          <motion.div
            style={{
              textAlign: "center",
              fontSize: theme.fontSizes.xxlarge,
              fontWeight: "bold",
              color: theme.colors.primary,
              cursor: "pointer",
            }}
            animate={{ scale: [1, 1.1, 1] }}
            transition={{ duration: 0.5 }}
            onClick={handleValueClick}
          >
            {ecSet} µS/cm
          </motion.div>
        )}
      </div>
    </Card>
  );
};

const PumpControl = ({
  pumpMode,
  manualPumpStatus,
  onPumpModeChange,
  onManualPumpStatusChange,
}) => {

  return (
    <Card>
      <h2
        style={{
          fontSize: theme.fontSizes.xlarge,
          fontWeight: "bold",
          marginBottom: theme.spacing.medium,
          color: theme.colors.text,
        }}
      >
        Pump Control
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing.large,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ color: theme.colors.text }}>Pump Mode</span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing.small,
            }}
          >
            <span
              style={{
                fontSize: theme.fontSizes.small,
                color:
                  pumpMode === "auto_schedule"
                    ? theme.colors.primary
                    : theme.colors.textSecondary,
              }}
            >
              Auto
            </span>
            <Switch
              checked={pumpMode === "manual"}
              onCheckedChange={() =>
                onPumpModeChange(pumpMode === "auto_schedule" ? "manual" : "auto_schedule")
              }
            />
            <span
              style={{
                fontSize: theme.fontSizes.small,
                color:
                  pumpMode === "manual"
                    ? theme.colors.primary
                    : theme.colors.textSecondary,
              }}
            >
              Manual
            </span>
          </div>
        </div>
        {pumpMode === "manual" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: theme.spacing.medium,
            }}
          >
            <TouchFriendlyButton
              active={manualPumpStatus}
              onClick={() => onManualPumpStatusChange(!manualPumpStatus)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Power size={24} />
            </TouchFriendlyButton>
          </div>
        )}
      </div>
    </Card>
  );
};

const PumpSchedule = ({ pumpMode, schedules, onScheduleChange }) => {
  const [newSchedule, setNewSchedule] = useState({
    time: "",
    duration: "",
    useAbMix: true,
  });
  
  const addSchedule = () => {
    if (newSchedule.time && newSchedule.duration) {
      const updatedSchedules = [...schedules, { ...newSchedule, id: Date.now() }];
      onScheduleChange(updatedSchedules);
      setNewSchedule({ time: "", duration: "", useAbMix: true });
    }
  };

  const deleteSchedule = (id) => {
    const updatedSchedules = schedules.filter((s) => s.id !== id);
    onScheduleChange(updatedSchedules);
  };

  return (
    <Card
      style={{
        backgroundColor:
          pumpMode === "manual" ? "#f0f0f0" : theme.colors.surface,
      }}
    >
      <h2
        style={{
          fontSize: theme.fontSizes.xlarge,
          fontWeight: "bold",
          marginBottom: theme.spacing.medium,
          color: theme.colors.text,
        }}
      >
        Pump Schedule
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing.medium,
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: theme.spacing.small,
          }}
        >
          <StyledInput
            type="time"
            value={newSchedule.time}
            onChange={(e) =>
              setNewSchedule({ ...newSchedule, time: e.target.value })
            }
            placeholder="Time"
            style={{ flex: 1 }}
          />
          <StyledInput
            type="number"
            value={newSchedule.duration}
            onChange={(e) =>
              setNewSchedule({ ...newSchedule, duration: e.target.value })
            }
            placeholder="Duration (min)"
            style={{ flex: 1 }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing.small,
            }}
          >
            <span
              style={{
                fontSize: theme.fontSizes.small,
                color: theme.colors.textSecondary,
              }}
            >
              AB Mix
            </span>
            <Switch
              checked={newSchedule.useAbMix}
              onCheckedChange={(checked) =>
                setNewSchedule({ ...newSchedule, useAbMix: checked })
              }
            />
          </div>
          <StyledButton
            onClick={addSchedule}
            style={{ width: "100%" }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Add
          </StyledButton>
        </div>
        <AnimatePresence>
          {schedules.map((schedule) => (
            <motion.div
              key={schedule.id}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: theme.spacing.medium,
                borderRadius: "0.5rem",
                backgroundColor: theme.colors.background,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing.medium,
                }}
              >
                <Clock size={20} color={theme.colors.primary} />
                <span style={{ color: theme.colors.text }}>
                  {schedule.time}
                </span>
                <span style={{ color: theme.colors.text }}>
                  {schedule.duration} min
                </span>
                <Droplets
                  size={20}
                  color={
                    schedule.useAbMix
                      ? theme.colors.primary
                      : theme.colors.textSecondary
                  }
                />
              </div>
              <StyledButton
                onClick={() => deleteSchedule(schedule.id)}
                style={{ padding: theme.spacing.small }}
                variant="danger"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Trash2 size={16} />
              </StyledButton>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </Card>
  );
};

const fetchLatestData = async () => {
  try {
    const response = await fetch('https://sgh_0002.tensaiinnovations.com/api/get/latest_data');
    if (!response.ok) {
      throw new Error('Failed to fetch latest data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching latest data:', error);
    return null;
  }
};

export function GreenhouseControlPage({ onBack }) {
  const [expandedCard, setExpandedCard] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [controlState, setControlState] = useState({
    pump_set: 'auto_schedule',
    pump_value: 0,
    ec_set: 2500,
    time: new Date().toISOString(),
    runtime: 770,
    nutrient: 'NPK',
    schedules: [] // Add this line
  });
    const [sensorData, setSensorData] = useState({
    ec: null,
    humd: null,
    temp: null,
  });

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      const latestData = await fetchLatestData();
      if (latestData) {
        setSensorData({
          ec: latestData.ec,
          humd: latestData.humd,
          temp: latestData.temp,
        });
        setControlState(prevState => ({
          ...prevState,
          pump_set: latestData.pump_set || prevState.pump_set,
          pump_value: latestData.pump_value || prevState.pump_value,
          ec_set: latestData.ec_set || prevState.ec_set,
          time: latestData.time || new Date().toISOString(),
          runtime: latestData.runtime || prevState.runtime,
          nutrient: latestData.nutrient || prevState.nutrient,
          schedules: latestData.schedules || [] // Add this line
        }));
      }
      setIsLoading(false);
    };

    initializeData();
  }, []);


  const updateControlState = async (updates) => {
    const newState = { ...controlState, ...updates, time: new Date().toISOString() };
    setControlState(newState);

    try {
      const response = await fetch('https://sgh_0002.tensaiinnovations.com/api/post/control', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newState)
      });

      if (!response.ok) {
        throw new Error('Failed to send control data');
      }

      const result = await response.json();
      console.log('Control response:', result);
    } catch (error) {
      console.error('Error during control operation:', error);
    }
  };

  const handleCardClick = (cardName) => {
    if (expandedCard === cardName) {
      setExpandedCard(null);
    } else {
      setExpandedCard(cardName);
    }
  };

  const handlers = useSwipeable({
    onSwipedRight: onBack,
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  });


  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading component
  }
  
  return (
    <Container
      {...handlers}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >

      <motion.div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: theme.spacing.large,
          marginBottom: theme.spacing.xlarge,
        }}
        variants={cardVariants}
      >
        <SensorCard
          icon={Thermometer}
          title="Temperature"
          value={sensorData.temp}
          unit="°C"
          data={sensorData}
          isExpanded={expandedCard === "temperature"}
          onClick={() => handleCardClick("temperature")}
        />
        <SensorCard
          icon={Droplets}
          title="Humidity"
          value={sensorData.humd}
          unit="%"
          data={sensorData}
          isExpanded={expandedCard === "humidity"}
          onClick={() => handleCardClick("humidity")}
        />
        <SensorCard
          icon={Wind}
          title="EC Value"
          value={sensorData.ec}
          unit="µS/cm"
          data={sensorData}
          isExpanded={expandedCard === "ec"}
          onClick={() => handleCardClick("ec")}
        />
      </motion.div>

       <TwoColumnLayout>
        <motion.div variants={cardVariants}>
          <ECAbMixControl 
            ecSet={controlState.ec_set} 
            onEcSetChange={(newEcSet) => updateControlState({ ec_set: newEcSet })} 
          />
        </motion.div>
        <motion.div variants={cardVariants}>
          <div style={{ marginBottom: theme.spacing.large }}>
            <PumpControl
              pumpMode={controlState.pump_set}
              manualPumpStatus={controlState.pump_value === 1}
              onPumpModeChange={(newMode) => updateControlState({ pump_set: newMode })}
              onManualPumpStatusChange={(newStatus) => updateControlState({ 
                pump_set: 'manual', 
                pump_value: newStatus ? 1 : 0 
              })}
            />
          </div>
          <PumpSchedule 
            pumpMode={controlState.pump_set} 
            schedules={controlState.schedules}
            onScheduleChange={(newSchedules) => updateControlState({ schedules: newSchedules })}
/>
        </motion.div>
      </TwoColumnLayout>
    </Container>
  );
}