import React, { useState } from "react";
import { Global, css } from "@emotion/react";
import { AnimatePresence } from "framer-motion";
import { LoginPage } from "./LoginPage";
import { GreenhouseListPage } from "./GreenhouseListPage";
import { GreenhouseControlPage } from "./GreenhouseControlPage";

const GlobalStyles = css`
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

  body {
    font-family: "Inter", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  * {
    box-sizing: inherit;
  }
`;

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedGreenhouse, setSelectedGreenhouse] = useState(null);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleSelectGreenhouse = (greenhouse) => {
    setSelectedGreenhouse(greenhouse);
  };

  const handleBack = () => {
    setSelectedGreenhouse(null);
  };

  return (
    <>
      <Global styles={GlobalStyles} />
      <AnimatePresence mode="wait">
        {!isLoggedIn ? (
          <LoginPage key="login" onLogin={handleLogin} />
        ) : selectedGreenhouse ? (
          <GreenhouseControlPage key="control" onBack={handleBack} />
        ) : (
          <GreenhouseListPage
            key="list"
            onSelectGreenhouse={handleSelectGreenhouse}
          />
        )}
      </AnimatePresence>
    </>
  );
}
